import defaultLocations from '~/data/default_locations.json';
import type { DashboardNewsAnnouncement, DashboardNewsAnnouncementFeaturedPackageOrder, NewsAnnouncementCategory, NewsAnnouncementFeaturedPackage, NewsAnnouncementQuota, NewsAnnouncementReportType, NewsAnnouncementTag, NewsPostCategory, NewsTicker } from '~/types';
interface NewsState {
    selected_news_announcement_category: object | null,
    current_dashboard_news_announcement: DashboardNewsAnnouncement|null,
    current_dashboard_news_announcement_expiry: number|null,
    current_dashboard_news_announcement_images: string[],
    current_dashboard_news_announcement_images_expiry: number|null,
    current_dashboard_news_announcement_orders: DashboardNewsAnnouncementFeaturedPackageOrder[],
    current_dashboard_news_announcement_orders_expiry: number|null,
    
    //current_news_search_location: object | null,
    //default_well_known_locations: object[],
    //is_searching: boolean,
    //current_news_search_query: string,
    //is_showing_search_suggestions: boolean,
    default_search_radius: number,
    default_search_sort_by: string,


    news_announcement_report_types: NewsAnnouncementReportType[]
    news_announcement_report_types_expiry: number|null,
    news_announcement_quota: NewsAnnouncementQuota|null,
    news_announcement_quota_expiry: number|null,
    news_announcement_categories: NewsAnnouncementCategory[]
    news_announcement_categories_expiry: number|null,
    news_tickers: NewsTicker[]
    news_tickers_expiry: number|null,
    news_announcement_tags: NewsAnnouncementTag[]
    news_announcement_tags_expiry: number|null,
    news_post_categories: NewsPostCategory[]
    news_post_categories_expiry: number|null,
    news_announcement_featured_packages: NewsAnnouncementFeaturedPackage[],
    news_announcement_featured_packages_expiry: number|null,
     
    caching_time: number;
}
export const useNews = defineStore('news', {
    state: (): NewsState => {
        return {
            selected_news_announcement_category: null,
            current_dashboard_news_announcement: null,
            current_dashboard_news_announcement_expiry: null,
            current_dashboard_news_announcement_images:[],
            current_dashboard_news_announcement_images_expiry:null,
            current_dashboard_news_announcement_orders:[],
            current_dashboard_news_announcement_orders_expiry:null,
            
            
            // current_news_search_location: {
            //     id: "place.9607189446701850",
            //     type: "Feature",
            //     place_type: ["place"],
            //     relevance: 1,
            //     properties: { wikidata: "Q1297" },
            //     text: "Chicago",
            //     place_name: "Chicago, Illinois, United States",
            //     bbox: [-87.931085223, 41.625740009, -87.507792006, 42.023137],
            //     center: [-87.6244, 41.8756],
            //     geometry: { type: "Point", coordinates: [-87.6244, 41.8756] },
            //     context: [
            //         {
            //             id: "district.8754923997749290",
            //             wikidata: "Q108418",
            //             text: "Cook County"
            //         },
            //         {
            //             id: "region.10854263468358810",
            //             wikidata: "Q1204",
            //             short_code: "US-IL",
            //             text: "Illinois"
            //         },
            //         {
            //             id: "country.19678805456372290",
            //             wikidata: "Q30",
            //             short_code: "us",
            //             text: "United States"
            //         }
            //     ]
            // },
            //default_well_known_locations: defaultLocations,
            //is_searching: false,
            //current_news_search_query: '',
            //is_showing_search_suggestions: false,
            default_search_radius: 100,
            default_search_sort_by: "latest",

            news_announcement_report_types: [],
            news_announcement_report_types_expiry: null,
            news_announcement_quota: null,
            news_announcement_quota_expiry: null,
            news_announcement_categories:[],
            news_announcement_categories_expiry: null,
            news_tickers:[],
            news_tickers_expiry: null,
            news_announcement_tags: [],
            news_announcement_tags_expiry: null,
            news_post_categories: [],
            news_post_categories_expiry: null,
            news_announcement_featured_packages: [],
            news_announcement_featured_packages_expiry: null,
          
            caching_time: 1000*10,
        }
    },
    actions: {
        setNewsAnnouncementCategory(news_announcement_category: object) {
            this.selected_news_announcement_category = news_announcement_category;
        },
        clearNewsAnnouncementCategory() {
            this.selected_news_announcement_category = null;
        },
        // setCurrentNewsLocation(location: object) {
        //     this.current_news_search_location = location;
        // },
        // clearCurrentNewsLocation() {
        //     this.current_news_search_location = null;
        // },

        setCurrentDashboardNewsAnnouncement(dashboardNewsAnnouncement: DashboardNewsAnnouncement) {
            this.current_dashboard_news_announcement = dashboardNewsAnnouncement;
            this.current_dashboard_news_announcement_expiry = Date.now();
        },
        clearCurrentDashboardNewsAnnouncement() {
            this.current_dashboard_news_announcement = null;
            this.current_dashboard_news_announcement_expiry = null;
        },
        isCurrentDashboardNewsAnnouncementValid(){
            if(this.current_dashboard_news_announcement_expiry !=null){
                return ((Date.now() - this.current_dashboard_news_announcement_expiry) < this.caching_time);
            }
            return true;
        },
        updateFeaturedUntilDateOfCurrentDashboardNewsAnnouncement(featured_until: string, featured_until_formatted:string) {
            if(this.current_dashboard_news_announcement){
                this.current_dashboard_news_announcement.featured_until = featured_until;
                this.current_dashboard_news_announcement.featured_until_formatted = featured_until_formatted;
            }
        },
        setCurrentDashboardNewsAnnouncementImages(imageUrls: string[]){
            this.current_dashboard_news_announcement_images = imageUrls;
            this.current_dashboard_news_announcement_images_expiry = Date.now();
        },
        clearCurrentDashboardNewsAnnouncementImages(){
            this.current_dashboard_news_announcement_images = [];
            this.current_dashboard_news_announcement_images_expiry = null;
        },
        isCurrentDashboardNewsAnnouncementImagesValid(){
            if(this.current_dashboard_news_announcement_images_expiry !=null){
                return ((Date.now() - this.current_dashboard_news_announcement_images_expiry) < this.caching_time);
            }
            return true;
        },
        setCurrentDashboardNewsAnnouncementOrders(orders: DashboardNewsAnnouncementFeaturedPackageOrder[]){
            this.current_dashboard_news_announcement_orders = orders;
            this.current_dashboard_news_announcement_orders_expiry = Date.now();
        },
        clearCurrentDashboardNewsAnnouncementOrders(){
            this.current_dashboard_news_announcement_orders = [];
            this.current_dashboard_news_announcement_orders_expiry = null;
        },
        addOrderToCurrentDashboardNewsAnnouncementOrders(order: DashboardNewsAnnouncementFeaturedPackageOrder){
            this.current_dashboard_news_announcement_orders.push(order);
        },
        updateOrderOfCurrentDashboardNewsAnnouncement(order: DashboardNewsAnnouncementFeaturedPackageOrder){
            const orderIndex = this.current_dashboard_news_announcement_orders.findIndex(adOrder => {
                return adOrder.id === order.id;
              });
              this.current_dashboard_news_announcement_orders[orderIndex] = order;
        },
        isCurrentDashboardNewsAnnouncementOrdersValid(){
            if(this.current_dashboard_news_announcement_orders_expiry !=null){
                return ((Date.now() - this.current_dashboard_news_announcement_orders_expiry) < this.caching_time);
            }
            return true;
        },
        addImageToCurrentDashboardNewsAnnouncementImages(imageUrl: string){
            this.current_dashboard_news_announcement_images.push(imageUrl);
            this.current_dashboard_news_announcement?.images.push(imageUrl);
        },
        removeImageFromCurrentDashboardNewsAnnouncementImagesAtIndex(index: number){
            this.current_dashboard_news_announcement_images.splice(index, 1);
            this.current_dashboard_news_announcement?.images.splice(index, 1);
        },
       
        // isSearching() {
        //     this.is_searching = true;
        // },
        //
        // isNotSearching() {
        //     this.is_searching = false;
        // },
        // setNewsSearchQuery(newsSearchQuery: any) {
        //     this.current_news_search_query = newsSearchQuery;
        // },
        // clearNewsSearchQuery() {
        //     this.current_news_search_query = "";
        // },
        setNewsAnnouncementCategories(newsAnnouncementCategories: any) {
            this.news_announcement_categories = newsAnnouncementCategories;
            this.news_announcement_categories_expiry = Date.now();
        },
        clearNewsAnnouncementCategories() {
            this.news_announcement_categories = [];
            this.news_announcement_categories_expiry = null;
        },
        isNewsAnnouncementCategoriesValid(){
            if(this.news_announcement_categories_expiry !=null){
                return ((Date.now() - this.news_announcement_categories_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsTickers(newsAnnouncementCategories: any) {
            this.news_tickers = newsAnnouncementCategories;
            this.news_tickers_expiry = Date.now();
        },
        clearNewsTickers() {
            this.news_tickers = [];
            this.news_tickers_expiry = null;
        },
        isNewsTickersValid(){
            if(this.news_tickers_expiry !=null){
                return ((Date.now() - this.news_tickers_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsPostCategories(newsPostCategories: any) {
            this.news_post_categories = newsPostCategories;
            this.news_post_categories_expiry = Date.now();
        },
        clearNewsPostCategories() {
            this.news_post_categories = [];
            this.news_post_categories_expiry = null;
        },
        isNewsPostCategoriesValid(){
            if(this.news_post_categories_expiry !=null){
                return ((Date.now() - this.news_post_categories_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsAnnouncementTags(newsAnnouncementTags: any) {
            this.news_announcement_tags = newsAnnouncementTags;
            this.news_announcement_tags_expiry = Date.now();
        },
        clearNewsAnnouncementTags() {
            this.news_announcement_tags = [];
            this.news_announcement_tags_expiry = null;
        },
        isNewsAnnouncementTagsValid(){
            if(this.news_announcement_tags_expiry !=null){
                return ((Date.now() - this.news_announcement_tags_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsAnnouncementReportTypes(newsAnnouncementReportTypes: any) {
            this.news_announcement_report_types = newsAnnouncementReportTypes;
            this.news_announcement_report_types_expiry = Date.now();
        },
        clearNewsAnnouncementReportTypes() {
            this.news_announcement_report_types = [];
            this.news_announcement_report_types_expiry = null;
        },
        isNewsAnnouncementReportTypesValid(){
            if(this.news_announcement_report_types_expiry !=null){
                return ((Date.now() - this.news_announcement_report_types_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsAnnouncementQuota(newsAnnouncementQuota: any) {
            this.news_announcement_quota = newsAnnouncementQuota;
            this.news_announcement_quota_expiry = Date.now();
        },
        clearNewsAnnouncementQuota() {
            this.news_announcement_quota = null;
            this.news_announcement_quota_expiry = null;
        },
        isNewsAnnouncementQuotaValid(){
            if(this.news_announcement_quota_expiry !=null){
                return ((Date.now() - this.news_announcement_quota_expiry) < this.caching_time);
            }
            return true;
        },
        setNewsAnnouncementFeaturedPackages(newsAnnouncementFeaturedPackages: NewsAnnouncementFeaturedPackage[]) {
            this.news_announcement_featured_packages = newsAnnouncementFeaturedPackages;
            this.news_announcement_featured_packages_expiry = Date.now();
        },
        clearNewsAnnouncementFeaturedPackages() {
            this.news_announcement_featured_packages = [];
            this.news_announcement_featured_packages_expiry = null;
        },
        isNewsAnnouncementFeaturedPackagesValid(){
            if(this.news_announcement_featured_packages_expiry !=null){
                return ((Date.now() - this.news_announcement_featured_packages_expiry) < this.caching_time);
            }
            return true;
        },
        // showSearchSuggestions() {
        //     this.is_showing_search_suggestions = true;
        // },
        // hideSearchSuggestions() {
        //     this.is_showing_search_suggestions = false;
        // }
    },
})